import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)
        this.columnWrapper = $(this.wrapper).parent()
        this.recommendationsList = this.columnWrapper.find(".recommendations-column-list")
        this.formWrapper = $("#filterrific_filter")
        this.preloadedRecommendations = this.wrapper.data('preloadedRecommendations') || 0;
        this.allRecommendations = this.wrapper.data('allRecommendations')
        this.recommendationsTableId = this.wrapper.data('recommendationsTableId')
        this.recommendationsColumnId = this.wrapper.data('recommendationsColumnId')
        this.recommendationsPerPage = this.wrapper.data('recommendationsPerPage')
        console.log("preloadedRecommendations: " + this.preloadedRecommendations)
        this.nowLoading = false;
        this.lastLoadedPage = 0

        // load anything only when it's not already preloaded
        this.wrapper.on('click', this._load_more_recommendations)
        this.wrapper.on('reload', this._reset_state)

        if(this.preloadedRecommendations < this.allRecommendations) {
            this.autoLoadPages = this.wrapper.data('autoLoadPages')
            if(this.autoLoadPages) {
                var observer = new IntersectionObserver((entries) => {
                    // isIntersecting is true when element and viewport are overlapping
                    // isIntersecting is false when element and viewport don't overlap
                    if(entries[0].isIntersecting === true) {
                        console.log("Button is visible");
                        this._load_more_recommendations();
                    }
                }, { threshold: [0] });
                observer.observe(this.wrapper[0]);
            }
        } else {
            console.log("Finished... everything preloaded");
            this.wrapper.hide()
        }

        this._find_last_contact_attempt()
    }

    disconnect() {
        this.wrapper.unbind('click');
        this.wrapper.unbind('reload');
    }

    _load_more_recommendations = () => {
        if(!this.nowLoading) {
            this.nowLoading = true;

            let currentCount = this.recommendationsList.children().size()
            let nextPage = Math.floor(currentCount / this.recommendationsPerPage + 1)

            if(nextPage > this.lastLoadedPage) {
                console.log("Started loading recommendations... nextPage: " + nextPage + ", currentCount: " + currentCount + ", recommendationsPerPage: " + this.recommendationsPerPage)

                Rails.ajax({
                    url:  '/recommendations_tables/' + this.recommendationsTableId + '/load_recommendations/' + this.recommendationsColumnId + '?' + this.formWrapper.serialize() + '&page=' + nextPage,
                    type: 'get',
                    success: this._show_recommendations,
                    complete: () => {
                        this.lastLoadedPage = nextPage;
                        this.nowLoading = false;
                        if(nextPage * this.recommendationsPerPage >= this.allRecommendations) {
                            console.log("Removing... nextPage: " + nextPage + ", recommendationsPerPage: " + this.recommendationsPerPage + ", allRecommendations: " + this.allRecommendations);
                            this.wrapper.remove()
                        } else if(this.autoLoadPages) {
                            setTimeout(() => {
                                if(this.wrapper.isInViewport()) {
                                    this._load_more_recommendations();
                                }
                            }, 500);
                        }
                    }
                });
            } else {
                console.log("Hiding... nextPage: " + nextPage + ", lastLoadedPage: " + this.lastLoadedPage);
                this.wrapper.hide()
            }
        } else {
            console.log("Already loading recommendations...");
        }
    }

    _show_recommendations = (recommendations) => {
        this.recommendationsList.append(recommendations.body.innerHTML)
        this._find_last_contact_attempt()
    }

    _find_last_contact_attempt = () => {
        this.columnWrapper.trigger('reloadLastContactAttempt')
    }

    _reset_state = () => {
        console.log('Resetting loading button...')
        this.lastLoadedPage = 0
        this.nowLoading = false;
        this.wrapper.fadeIn();
    }
}